<template>
<v-container>

    <Location @location="onClickChild" />
    <div style="height: 1000px; width: 100%">

        <l-map ref="map" v-if="showMap" :zoom="zoom" :center="center" :options="mapOptions" style="height: 80%" @update:center="centerUpdate" @update:zoom="zoomUpdate">
            <l-tile-layer :url="url" :attribution="attribution" />

            <l-marker v-for="item in this.$store.getters.places" :key="item.name" :lat-lng="[item.lat, item.lon]">
                <l-popup>

                    <div @click="innerClick">
                        <p class="text-center">
                            {{item.name}}
                        </p>
                        <p>
                            <b>Ved tilgjengelig:</b> {{item.firewood}} <br>
                            <b>Værmelding nå:</b> {{item.now}} <br>
                            <b>Værmelding om 1 time:</b> {{item.hour1}} <br>
                            <b>Værmelding om 2 time:</b> {{item.hour2}} <br>
                        </p>
                    </div>
                </l-popup>
            </l-marker>

        </l-map>
    </div>

</v-container>
</template>

<script>
import Location from '@/components/Location.vue';
import {
    Icon
} from 'leaflet'
import 'leaflet/dist/leaflet.css'

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

import {
    latLng
} from "leaflet";
import {
    LMap,
    LTileLayer,
    LPopup,
    LMarker
} from "vue2-leaflet";

export default {
    name: "Example",
    components: {
        LMap,
        LTileLayer,
        LPopup,
        LMarker,
        Location
    },
    data() {
        return {
            map: null,
            zoom: 11,
            center: latLng(59.48469, 7.4358591),
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            showParagraph: false,
            currentZoom: 18,
            // currentCenter: latLng(59.48469, 7.4358591),
            fire1: latLng(59.4785257, 7.4340446),
            mapOptions: {
                zoomSnap: 0.5
            },
            showMap: true
        };
    },

    methods: {
        onClickChild(value) {
            this.$refs.map.mapObject.flyTo(latLng(value.lat, value.lng));

        },
        zoomUpdate(zoom) {
            this.currentZoom = zoom;
        },
        centerUpdate(center) {
            this.currentCenter = center;
        },
        showLongText() {
            this.showParagraph = !this.showParagraph;
        },
        innerClick() {

        }
    },
};
</script>
