<template>
<div v-cloak>
    <button @click="locateMe">
        <v-icon>mdi-near-me</v-icon>
    </button>

    <div v-if="errorStr">
        Sorry, but the following error
        occurred: {{errorStr}}
    </div>

    <div v-if="gettingLocation">
        <i>Getting your location...</i>
    </div>

    <div v-if="location">
        Your location data is {{ location.coords.latitude }}, {{ location.coords.longitude}}
    </div>

</div>
</template>

<script>
import axios from "axios"
export default {
    data: () => ({
        location: null,
        gettingLocation: false,
        errorStr: null,

    }),
    methods: {
        async getLocation() {

            return new Promise((resolve, reject) => {

                if (!("geolocation" in navigator)) {
                    reject(new Error('Geolocation is not available.'));
                }

                navigator.geolocation.getCurrentPosition(pos => {
                    resolve(pos);
                }, err => {
                    reject(err);
                });

            });
        },
        async locateMe() {

            this.gettingLocation = true;
            try {
                this.gettingLocation = false;
                this.location = await this.getLocation();
                this.$emit("location", {
                    lat: this.location.coords.latitude,
                    lng: this.location.coords.longitude,
                });

            } catch (e) {
                this.gettingLocation = false;
                this.errorStr = e.message;
            }

        },
    },
        mounted() {
        //let users = [];
        let promises = [];
        for (let firePlace of this.$store.getters.places) {
            promises.push(
                axios.get('https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=' + firePlace.lat + '&lon=' + firePlace.lon).then(response => {
                    firePlace.now = response.data.properties.timeseries[0].data.instant.details.wind_speed;
                    firePlace.hour1 = response.data.properties.timeseries[1].data.instant.details.wind_speed;
                    firePlace.hour2 = response.data.properties.timeseries[2].data.instant.details.wind_speed;
                    //      users.push(response);
                    console.log(response)
                }).catch(error => {
                    console.log(error)
                    this.errored = true
                })
            )
        }
        

        Promise.all(promises).then().finally(() => this.tableLoading = false);
    }
}
</script>
